@font-face {
    font-family: "playfair-display";
    src: url("/static/website/assets/fonts/playfair-display.ttf") format("truetype");
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: "satoshi-variable";
    src: url("/static/website/assets/fonts/satoshi-variable.ttf") format("truetype");
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: "roboto-mono";
    src: url("/static/website/assets/fonts/roboto-mono.ttf") format("truetype");
    font-weight: 100 900;
    font-style: normal;
}

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}
html::-webkit-scrollbar {
    width: 0;
}

body {
    background-color: #FFFFFF;
    overflow-x: hidden;
}

main {
    position: relative;
}

/* For mobile screens */
@media (min-width: 320px) and (max-width: 480px) {
}

/* For tablet screens */
@media (min-width: 481px) and (max-width: 768px) {
}

/* For small laptop screens */
@media (min-width: 769px) and (max-width: 1024px) {
}

/* For large laptop screens */
@media (min-width: 1440px) and (max-width: 1679px) {
}

/* For extra large desktop screens */
@media (min-width: 1680px) {
}
