* {
    padding: 0px; 
    margin: 0px; 
}

.veneersCanvas {
    position: relative; 

    width: 100%;
    height: 100%; 
}

.veneersCanvas > canvas {
    position: absolute; 
    right: 0px; 
    z-index: 30; 

    height: 100%; 
}


/* For mobile screens */
@media (min-width: 320px) and (max-width: 480px) {
    .veneersCanvas > canvas {    
        width: 100%; 
    }
}

/* For tablet screens */
@media (min-width: 481px) and (max-width: 768px) {
    .veneersCanvas > canvas {
        width: 100%; 
    }
}

/* For small laptop screens */
@media (min-width: 769px) and (max-width: 1024px) {
}

/* For laptop screens */
@media (min-width: 1025px) and (max-width: 1439px) {
    .veneersCanvas > canvas {
        width: 100%; 
    }
}

/* For large laptop screens */
@media (min-width: 1440px) and (max-width: 1679px) {
    .veneersCanvas > canvas {
        width: 100%; 
    }
}

/* For extra large desktop screens */
@media(min-width: 1680px) {
    .veneersCanvas > canvas {
        width: 100%; 
    }
}
